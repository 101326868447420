<template>
  <div class="th-list-course">
    <h2 class="th-title"><b> Data Center Dashboard</b></h2>
    <div class="row px-3 pt-3 justify-content-between" v-if="false">
      <div class="card col-xl-3 d-inline-block p-1">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center">
            <div class="mr-auto">
              <p class="text-uppercase text-dark font-weight-bold mb-1">Active Assets</p>
              <p class="text-gray-5 mb-0">Aset dengan garansi aktif</p>
            </div>
            <p class="text-success font-weight-bold font-size-24 mb-0">251</p>
          </div>
        </div>
      </div>
      <div class="card col-xl-4 d-inline-block p-1">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center">
            <div class="mr-auto">
              <p class="text-uppercase text-dark font-weight-bold mb-1">Expiring Assets</p>
              <p class="text-gray-5 mb-0">Garansi akan berakhir dalam 3 bulan</p>
            </div>
            <p class="text-warning font-weight-bold font-size-24 mb-0">24</p>
          </div>
        </div>
      </div>
      <div class="card col-xl-4 d-inline-block p-1">
        <div class="card-body">
          <div class="d-flex flex-wrap align-items-center">
            <div class="mr-auto">
              <p class="text-uppercase text-dark font-weight-bold mb-1">Expired Assets</p>
              <p class="text-gray-5 mb-0">Aset dengan garansi berakhir</p>
            </div>
            <p class="text-danger font-weight-bold font-size-24 mb-0">10</p>
          </div>
        </div>
      </div>
    </div>
    <a-card>
      <div class="mb-3 row">
        <div class="col-2 m-1">
          <a-input-search placeholder="Cari Nama atau ID Aset..." @input="handleSearch" v-model:value="search" />
        </div>
        <!-- <div class="col-2 m-1">
          <a-select v-model:value="selectedFilterLokasi" style="width: 220px">
            <a-select-option v-for="(item, i) in filterLokasi" :key="i" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </div>
        <div class="col-2 m-1">
          <a-select v-model:value="selectedFilterRuang" style="width: 220px;margin-left: 40px;">
            <a-select-option v-for="(item, i) in filterRuang" :key="i" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </div> -->
        <div class="col m-1 w-100 d-flex justify-content-end align-items-center">
          <!-- <router-link :to="`${currentRoute.currentRoutes}`">
            <a-button><PlusOutlined />New {{ currentRoute.strRouteCapital }}</a-button>
          </router-link> -->
        </div>
      </div>
      <div class="w-100" style="overflow-x:auto ;">
        <a-table :pagination="globalPagination" :columns="columns" :data-source="filteredData" class="table" bordered
          table-layout="auto" :loading="loadingTable" @change="handleTableChange" :row-key="record => record.id">
          <template #Asset="{ text, record }">
            <router-link class="th-link" :to="{
              name: 'Detail Testing',
              params: { id: record.id },
            }">
              {{ text ? text.name : '-' }}
            </router-link>
            <br />
            <!-- <small>
              {{ text.AssetSubCategory.name }}
            </small> -->
          </template>
          <template #AssetCat="{ record }">
            <span>{{ record.Asset ? record.Asset.AssetSubCategory.AssetCategory.name : '-' }}</span>
            <br />
            <small>
              <span>{{ record.Asset ? record.Asset.AssetSubCategory.name : '-' }}</span>
            </small>
          </template>
          <template #createdAt="{ text }">
            {{ formatDate(text) }}
          </template>
          <template #location="{ record }">
            {{ record.Asset.Location.name }}
            <br />
            <small>
              {{ record.Asset.Room.name }}
            </small>
          </template>
          <template #solving="{ record }">
            {{
              record.TestingMasters.length
              ? record.TestingMasters[0].TestingRemark.testingReason
              : '-'
            }}
          </template>
        </a-table>
      </div>
    </a-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  onMounted,
  ref,
  reactive,
  createVNode,
  toRef,
  toRefs,
  computed,
  onBeforeMount,
  watch,
} from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { baseURL } from '@/services/axios'
import {
  CheckOutlined,
  ToolOutlined,
  PhoneOutlined,
  SearchOutlined,
  PlusOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  ExclamationCircleOutlined,
  CloseSquareOutlined,
  InfoCircleOutlined,
  HomeOutlined,
} from '@ant-design/icons-vue'
import rupiahPrice from '@/helpers/rupiahPrice'

import fuzzysort from 'fuzzysort'
import { Modal, notification, message } from 'ant-design-vue'
import { useForm } from '@ant-design-vue/use'
import axios from 'axios'
import moment from 'moment'
import lodash from 'lodash'
import assetsFile from '@/data/modc_dump.json'
import { randomUUID } from '@/helpers/randomUUID'
import { getAssets } from '../../services/axios/modc/assets'
import { getListChangeRequest } from '../../services/axios/modc/helpdesk'

const columns = [
  {
    title: 'NAMA ASSET',
    dataIndex: 'Asset',
    className: '',
    key: 'Asset',
    slots: { customRender: 'Asset' },
    width: 150,
    // sorter: true,
  },
  {
    title: 'KATEGORI ASSET',
    dataIndex: 'Asset',
    className: '',
    key: 'AssetCat',
    slots: { customRender: 'AssetCat' },
    width: 200,
    // sorter: true,
  },
  {
    title: 'TIPE REQUEST',
    dataIndex: 'type',
    className: '',
    key: 'type',
    slots: { customRender: 'type' },
    width: 150,
    // sorter: true,
  },
  {
    title: 'TANGGAL REQUEST',
    dataIndex: 'createdAt',
    className: '',
    key: 'createdAt',
    slots: { customRender: 'createdAt' },
    width: 200,
    // sorter: true,
  },
  {
    title: 'LOKASI',
    dataIndex: 'location',
    className: '',
    key: 'location',
    slots: { customRender: 'location' },
  },

  {
    title: 'SOLVING',
    dataIndex: 'solving',
    className: '',
    key: 'solving',
    slots: { customRender: 'solving' },
    filters: [
      /**"Reparation",
        "Part Replacement",
        "Reconfiguration" */
      {
        text: 'New Installation',
        value: 'New Installation',
      },
      {
        text: 'Drill/Simulation',
        value: 'Drill/Simulation',
      },
      {
        text: 'Post Reparation',
        value: 'Post Reparation',
      },
    ],
    // "New Installation",
    // "Drill/Simulation",
    // "Post Reparation"
    // filters: ['Reparation', 'Part Placement', 'Reconfiguration'],
    onFilter: (value, record) => {
      if (record.TestingMasters.length) {
        return record.TestingMasters[0].TestingRemark.testingReason == value
      } else {
        return false
      }
    },
  },
  {
    title: 'STATUS',
    dataIndex: 'status',
    className: '',
    key: 'status',
    slots: { customRender: 'status' },
    filters: [
      {
        text: 'Complated',
        value: 'Complated',
      },
      {
        text: 'Pending',
        value: 'Pending',
      },
    ],
    // filters: ['Reparation', 'Part Placement', 'Reconfiguration'],
    onFilter: (value, record) => {
      return record.status == value
    },
  },
  {
    title: 'NOTES',
    dataIndex: 'notes',
    className: '',
    key: 'notes',
    slots: { customRender: 'notes' },
    // width: 100,
    // sorter: true,
  },
]

export default {
  components: {
    CloudUploadOutlined,
    CloudDownloadOutlined,
    InfoCircleOutlined,
    CheckOutlined,
    ToolOutlined,
    PhoneOutlined,
    HomeOutlined,
    PlusOutlined,
  },
  setup() {
    /* eslint-disable */
    let store = useStore()
    const route = useRoute()
    let filteredData = ref([])
    let isSearching = ref(false)
    let loadingTable = ref(false)
    let globalPagination = ref({ current: 1, pageSize: 10 })
    let totalPages = ref([])

    let search = ref('')
    const currentRoute = computed(() => {
      const currentRoute = route.path.split('/').at(1)
      let strRouteCapital = currentRoute.split('')[0].toUpperCase() + currentRoute.slice(1)

      // let typeAssets = ''
      // switch (route.path.split('/').at(1)) {
      //   case 'facilities':
      //     typeAssets = 'Facility '
      //     break
      //   case 'passive':
      //     typeAssets = 'Passive '
      //     break
      //   case 'active':
      //     typeAssets = 'Active '
      //     break

      //   default:
      //     break
      // }
      // console.log(route.path.split('/').at(1) + '/create')
      return {
        strRouteCapital,
        currentRoutes: route.path.split('/').at(1) + '/create',
      }
    })

    //

    let filterLokasi = [
      'Filter Lokasi',
      'DC Salemba',
      'DC Depok',
      'Headquarter',
      'DC Graha Mustika',
      'Bakrie Center',
    ]
    let selectedFilterLokasi = ref('Filter Lokasi')
    let filterRuang = ref(['Filter Ruang', 'Network Operation', 'Main Genset', 'Ruang Testing'])
    let selectedFilterRuang = ref('Filter Ruang')

    const onSearch = async searchText => {
      if (searchText !== '') {
        isSearching.value = true
        loadingTable.value = true
        const res = await searchPotensi(searchText)
        console.log(res, 'res search')
        // const results = fuzzysort.go(searchText, potensiData.value, { key: 'name' })
        // const sortData = results.map(result => {
        //     return result.obj
        // })
        filteredData.value = res.result
        loadingTable.value = false
      } else {
        isSearching.value = false
        loadingTable.value = false
        fetchCourse(1)
      }
    }
    onBeforeMount(() => {
      fetchCourse()
    })
    // onMounted(() => {
    //   fetchCourse()
    // })
    const fetchCourse = async params => {
      loadingTable.value = true

      // console.log(route.path.split('/').at(-1), '<<<ROUTE', assetSubCategory, '<<<ASSET')
      const userLogin = JSON.parse(localStorage.getItem('userData'))
      const payload = {
        helpDeskId: userLogin.user.id,
        type: 'Testing',
        search: search.value,
      }
      const data = await getListChangeRequest(payload)
      console.log(data, '<<<<<DATA')
      filteredData.value = data.changeRequestList

      loadingTable.value = false
      isSearching.value = false
      // globalPagination.value = { ...globalPagination.value, total: 0 }
      globalPagination.value = { ...globalPagination.value, total: data.changeRequestList.length }
    }

    const lodashSearch = lodash.debounce(() => fetchCourse(), 1500)
    const handleSearch = () => {
      loadingTable.value = true
      globalPagination.value.current = 1
      lodashSearch()
      setTimeout(() => {

        loadingTable.value = false
        // console.log('masuk baru')

      }, 2500);
      // fetchCourse({ search: search.value })
    }
    const handleTableChange = (pag, filters, sorter) => {
      globalPagination.value.current = pag.current
      // if (sorter) {
      //   if (sorter.columnKey == 'key') sortBy.value = '_id'
      //   else sortBy.value = sorter.columnKey
      //   order.value = sorter.order
      // }
      fetchCourse()
    }
    const formatDate = date => {
      return moment(date).format('DD MMM YYYY HH:mm:ss')
    }
    watch(route, (to, from) => {
      fetchCourse()
    })

    return {
      handleSearch,
      handleTableChange,
      currentRoute,
      search,
      filterLokasi,
      selectedFilterLokasi,
      filterRuang,
      selectedFilterRuang,
      globalPagination,
      columns,
      filteredData,
      loadingTable,
      formatDate,
    }
  },
}
</script>
<style lang="scss">
@import '../styleViews.scss';

.text-customer {
  font-size: 0.75rem;
}

.th-list-course {
  .th-link {
    color: var(--vb-color-primary) !important;

    &:hover {
      color: var(--kit-color-primary) !important;
    }
  }
}
</style>
